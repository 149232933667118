var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{attrs:{"id":"dashboard-analytics"}},[_c('div',{staticClass:"dashboard-analytics-content"},[_c('div',{staticClass:"vx-row"},[_c('div',{staticClass:"vx-col w-full"},[_c('div',{staticClass:"metrics-header"},[_c('h4',{staticClass:"mb-4"},[_vm._v(_vm._s(_vm.title))]),_vm._v(" "),_c('KonaAlert',{directives:[{name:"show",rawName:"v-show",value:(_vm.updatedTime),expression:"updatedTime"}],attrs:{"icon":"ClockIcon","bg-color":"rgba(var(--vs-warning), 0.15)","color":"rgba(var(--vs-warning), 1)","size":"small"}},[(_vm.updatedTime)?_c('span',[_vm._v("\n              "+_vm._s(_vm.lang.metrics.lastUpdate[_vm.languageSelected])+"\n              "+_vm._s(_vm._f("moment")(_vm.updatedTime,_vm.dateFormat))+"\n            ")]):_vm._e(),_vm._v(" "),_c('a',{staticClass:"refresh",on:{"click":_vm.refresh}},[_vm._v("\n              "+_vm._s(_vm.lang.metrics.refresh[_vm.languageSelected])+"\n            ")])])],1),_vm._v(" "),_c('Filters',{attrs:{"type":"card","filter-model":_vm.filterModel},on:{"init":_vm.filterChange,"on-apply":_vm.onApplyFilters}})],1)]),_vm._v(" "),(_vm.$route.path !== '/knowledge' && _vm.$route.path !== '/dashboard')?_c('KonaTabs',{attrs:{"tabsStyle":_vm.tabsStyle}},[_c('KonaTab',{attrs:{"name":_vm.lang.metrics.tabs.general[_vm.languageSelected],"icon":"settings","selected":true}},[_c('draggable',{staticClass:"vx-row"},_vm._l((_vm.reports),function(item,index){return (
              _vm.REPORTS_MODEL[item.reportType] &&
                _vm.isSelectedReport(item) &&
                _vm.isGenericChart(item)
            )?_c('div',{key:("chart_" + index),staticClass:"vx-col mb-base cursor-move",class:_vm.REPORTS_MODEL[item.reportType].classes},[_c(_vm.REPORTS_MODEL[item.reportType].component,_vm._b({tag:"component"},'component',{
                report: item,
                services: _vm.services,
                interval: _vm.interval,
                platforms: _vm.platforms,
                versions: _vm.versions
              },false))],1):_vm._e()}))],1),_vm._v(" "),_c('KonaTab',{attrs:{"name":_vm.lang.metrics.tabs.contact[_vm.languageSelected],"icon":"chat_bubble_outline"}},[_c('draggable',{staticClass:"vx-row"},_vm._l((_vm.reports),function(item,index){return (
              _vm.REPORTS_MODEL[item.reportType] &&
                _vm.isSelectedReport(item) &&
                _vm.isContactCenterChart(item)
            )?_c('div',{key:("chart_" + index),class:("vx-col mb-base cursor-move " + (_vm.REPORTS_MODEL[item.reportType].classes))},[_c(_vm.REPORTS_MODEL[item.reportType].component,_vm._b({tag:"component"},'component',{
                report: item,
                services: _vm.services,
                interval: _vm.interval,
                platforms: _vm.platforms,
                versions: _vm.versions
              },false))],1):_vm._e()}))],1)],1):(_vm.$route.path !== '/dashboard')?_c('draggable',{staticClass:"vx-row",class:{ 'knowledge-wrapper': _vm.$route.path === '/knowledge' }},_vm._l((_vm.reports),function(item,index){return (_vm.REPORTS_MODEL[item.reportType] && _vm.isSelectedReport(item))?_c('div',{key:("chart_" + index),staticClass:"vx-col mb-base cursor-move",class:_vm.REPORTS_MODEL[item.reportType].classes},[_c(_vm.REPORTS_MODEL[item.reportType].component,_vm._b({tag:"component"},'component',{
            report: item,
            services: _vm.services,
            interval: _vm.interval,
            platforms: _vm.platforms,
            versions: _vm.versions
          },false))],1):_vm._e()})):_c('draggable',{staticClass:"vx-row"},_vm._l((_vm.reports),function(item,index){return (_vm.REPORTS_MODEL[item.reportType] && _vm.isSelectedReport(item))?_c('div',{key:("chart_" + index),staticClass:"vx-col w-full sm:w-1/2 md:w-1/2",class:_vm.REPORTS_MODEL[item.reportType].classes},[_c(_vm.REPORTS_MODEL[item.reportType].component,_vm._b({tag:"component"},'component',{
            report: item,
            services: _vm.services,
            interval: _vm.interval,
            platforms: _vm.platforms,
            versions: _vm.versions
          },false))],1):_vm._e()}))],1)])}
var staticRenderFns = []

export { render, staticRenderFns }